<template>
    <div class="about-cource show-md mb-4" id="top-to-cart">
        <div class="introduction-cource2">
            <span class="title2">توضیحات دوره</span>
            <hr>
        </div>
        <div class="price" v-if="price != '0'">
            <div class="d-flex align-items-center">
                <i class="icon-cd"></i>
                <span class="num1">تعداد دی وی دی ها:</span>
            </div>
            <span class="num2">{{ dvd }} عدد</span>
        </div>
        <div class="price">
            <div class="d-flex align-items-center">
                <i class="icon-download"></i>
                <span class="num1">حجم فایل ها:</span>
            </div>
            <span class="num2">{{ size }} گیگابایت</span>
        </div>
        <div class="price">
            <div class="d-flex align-items-center">
                <i class="icon-passage-of-time"></i>
                <span class="num1">مدت دوره:</span>
            </div>
            <span class="num2">{{ time }}</span>
        </div>
        <div class="price">
            <div class="d-flex align-items-center">
                <i class="icon-user"></i>
                <span class="num1">تولید کنندگان:</span>
            </div>
            <span class="num2">{{ teachers }}</span>
        </div>

        <div class="input-row my-color" v-if="studentOfThisCourse=='yes'">
            شما دانشجوی این دوره هستید
        </div>

        <div class="price" v-if="studentOfThisCourse=='no' && price != '0'">
            <div class="d-flex align-items-center">
                <i class="icon-package"></i>
                <span class="num1">شیوه ارسال:</span>
            </div>
            <span class="num2" v-if="type === 'both'">پستی و دانلود</span>
            <span class="num2" v-if="type === 'download'">دانلود</span>
            <span class="num2" v-if="type === 'post'">پستی</span>
        </div>


        <form v-if="studentOfThisCourse=='no' && price != '0'">
            <div class="input-row">
                <input id="checkbox-input-6" type="checkbox" value="post" class="kh-disable"
                       @change.prevent="changeCheckBoxes()"
                       @click="setChange('post')"
                       v-model="checkPost"
                       v-if="type!='download'">
                <label for="checkbox-input-6" class="input-helper input-helper--checkbox notselect kh-disable"
                       v-if="type!='download'"
                       @click="setChange('post')">خرید
                    پستی</label>

                <input id="checkbox-input-5"
                       @click="setChange('download')" v-model="checkDownload" class="kh-disable" type="checkbox"
                       value="download"
                       @change.prevent="changeCheckBoxes()"
                       v-if="type!='post'">
                <label for="checkbox-input-5"
                       @click="setChange('download')" class="input-helper input-helper--checkbox notselect kh-disable"
                       v-if="type!='post'">خرید دانلودی</label>
            </div>
        </form>
        <div v-if="isSell == 1">
            <div class="cource-price" v-if="studentOfThisCourse=='no' && price != '0'">
                <div class="d-flex flex-column">
                    <span>قیمت</span>
                    <span class="your-price">{{ numberWithCommas(showFinalPrice) }} تومان</span>
                </div>
                <span class="cost-price" v-if="isDiscount">{{ numberWithCommas(showPrice) }} تومان</span>
            </div>
            <div class="cource-price" v-if="studentOfThisCourse=='no' && price == '0'">
                <div class="d-flex flex-column w-100">
                    <span>قیمت</span>
                    <span class="your-price text-center">رایگان</span>
                </div>
            </div>
            <button v-if="studentOfThisCourse=='no'" class="cource-basket" @click.prevent="addToCart(cid)">
                افزودن به سبد
                خرید
            </button>
        </div>
        <div v-else>
            <button
                class="cource-basket mt-5"
            >
                موجود نمی باشد!
            </button>
        </div>
        <!--  <button v-if="studentOfThisCourse=='no' && price == '0'" class="cource-basket"
                 @click.prevent="getFreeCourse(cid)">
             ثبت نام رایگان در دوره!
         </button> -->
    </div>
</template>

<script>
export default {
    props: [
        'dvd',
        'size',
        'time',
        'teachers',
        'type',
        'price',
        'finalPrice',
        'postPrice',
        'isSell',
        'cid',
        'studentOfThisCourse'
    ],
    data() {
        return {
            showFinalPrice: parseInt(this.finalPrice),
            showPrice: parseInt(this.price),
            getType: 'download',
            checkPost: false,
            checkDownload: false,
            interval: null,
            timer: null,
            isDiscount: false
        };
    },
    mounted() {
        if (this.showPrice > this.showFinalPrice)
            this.isDiscount = true;
        this.getType = "download";
    },
    methods: {
        getFreeCourse(id) {
            let _this = this;
            _this.$store.commit('switchLoading', true);
            axios({
                method: "post",
                url: '/course/register/free',
                data: {
                    id: id
                }
            }).then((response) => {
                _this.$store.commit('switchLoading', false);
                if (response.data == "login") {
                    _this.message('برای ثبت نام در این دوره باید وارد حساب کاربری خود شده باشید');
                    $("#my-login-modal").modal('show');
                } else if (response.data == "notFree") {
                    _this.message('دوره رایگان نمی باشد');
                } else if (response.data == "register") {
                    _this.message('شما قبلا در این دوره ثبت نام کرده اید');
                } else {
                    _this.message('شما با موفقیت در دوره ثبت نام شدید', 'success');
                    _this.messageSwal('شما با موفقیت در دوره ثبت نام شدید', 'success');
                    setTimeout(function () {
                        location.reload();
                    }, 1500);
                }
            }).catch((err) => {
                console.log(err)
                _this.$store.commit('switchLoading', false);
                _this.message('خطا در ذخیره سازی اطلاعات');
            });
        },
        setChange(type) {
            if (type == 'download') {
                this.checkPost = false;
            }
            if (type == 'post') {
                this.checkDownload = false;
            }
        },
        message(message, type = 'error') {
            Vue.$toast.open({
                message: message,
                type: type,
                position: "top-left",
                duration: 5000
                // all other options
            });
        },
        messageSwal(message, type = 'error') {
            Swal.fire({
                type: type,
                title: message,
                showConfirmButton: false,
                timer: 2000
            })
        },
        addToCart(id) {
            let _this = this;
            if (!_this.checkPost && !_this.checkDownload && this.price != '0') {
                _this.message('شیوه ارسال خود را انتخاب نمایید');
            } else {
                _this.$store.commit('switchLoading', true);
                axios({
                    method: "post",
                    url: '/cart/add',
                    data: {
                        id: id,
                        type: _this.getType
                    }
                }).then((response) => {
                    _this.$store.commit('switchLoading', false);
                    if (response.data == "overflow") {
                        _this.message('سفارش این دوره به حداکثر رسیده است');
                    } else {
                        _this.message('دوره مورد نظر به سبد خرید شما اضافه شد', 'success');
                        _this.messageSwal('دوره مورد نظر به سبد خرید شما اضافه شد', 'success');
                        _this.$store.commit('updateCart');
                        _this.$store.commit('getTotal');
                        _this.$forceUpdate();
                    }
                }).catch((err) => {
                    _this.$store.commit('switchLoading', false);
                    _this.message('خطا در ذخیره سازی اطلاعات');
                });
            }
        },
        changeCheckBoxes() {
            let price = this.showFinalPrice;
            let lastGetType = this.getType;

            if (this.checkDownload && !this.checkPost) {
                this.getType = "download";
                if (lastGetType == 'both' || lastGetType == 'post') {
                    this.setNewPriceEffect(price - parseInt(this.postPrice), 'minus');
                }
                return true;
            }
            if (!this.checkDownload && this.checkPost) {
                this.getType = "post";
                if (lastGetType != 'both') {
                    this.setNewPriceEffect(price + parseInt(this.postPrice), 'add');
                }
                return true;
            }
            if (!this.checkDownload && !this.checkPost) {
                this.getType = "";
                if (lastGetType == 'both' || lastGetType == 'post') {
                    this.setNewPriceEffect(price - parseInt(this.postPrice), 'minus');
                }
                return true;
            }
            return false;
        },
        setNewPriceEffect(newPrice, type) {
            let _this = this;
            if (type === "add") {
                _this.interval = setInterval(function () {
                    clearTimeout(_this.timer);
                    if (_this.showFinalPrice >= newPrice) {
                        _this.showFinalPrice = newPrice;
                        clearInterval(_this.interval);
                    } else {
                        _this.showFinalPrice += 150;
                    }
                }, 1);
            } else {
                _this.interval = setInterval(function () {
                    clearTimeout(_this.timer);
                    if (_this.showFinalPrice <= newPrice) {
                        _this.showFinalPrice = newPrice;
                        clearInterval(_this.interval);
                    } else {
                        _this.showFinalPrice -= 150;
                    }
                }, 1);
            }
        },
        delComma(text) {
            let temp = text.split(',');
            return temp.join('');
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
    computed: {
        cart() {
            return this.$store.state.cart;
        },
        cartTotal() {
            return this.$store.state.cartTotal;
        }
    }
};
</script>
